import React, { useRef, useEffect, useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";
import * as PR from "../../prime-modules/index";
import { esimIcon } from "../../assets/images";
import { useTranslation } from "react-i18next";
import BundlesList from "../../components/EsimBundles";
import HeaderMenu from "../../components/layout/HeaderMenu";
import "../home/Home.scss";
import faq_en from "../../assets/data/faq_en.json";
import faq_es from "../../assets/data/faq_es.json";
import faq_pt from "../../assets/data/faq_pt.json";
import { useDispatch, useSelector } from "react-redux";
import { scrollToBundlesActions } from "../../store/scrollToBundles";
import getAssets from '../../assets/data/brandingAssets.json';
import { globalConfig } from "../../constants";
import ContactForm from "../../components/ContactForm";
import { ScrollTo } from "../../utils/ScrollTo";
import { useToast } from "../../context/ToastContext";
import getAssests from "../../assets/data/brandingAssets.json"

const Home = () => {
  const { i18n } = useTranslation();
  const { home,contact, partner,labels, isBase64Image } = useToast();
  const dispatch = useDispatch();
  const toast = useRef(null);
  const scrollToBundlesState = useSelector(state => state.scrollToBundles.scrollToBundlesState);
  const ref = useRef(null);
  const [faq, setFaq] = useState([]);

  const handleClick = () => {
    ScrollTo(ref, -120);
  };

  useEffect(() => {
    if (scrollToBundlesState) {
      handleClick();
      dispatch(scrollToBundlesActions.deleteSrollToBundles());
    }

    const defaultLang = i18n.language.split("-")[0];
    if (defaultLang === "en") {
      setFaq(faq_en);
    } else if (defaultLang === "es") {
      setFaq(faq_es);
    } else if (defaultLang === "pt") {
      setFaq(faq_pt);
    }
  }, [ i18n, scrollToBundlesState, dispatch]);

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{globalConfig.domainName} | awesim</title>
      </Helmet>
      {/* Header Section */}
      <section className="header-section" style={{ background: `url(${isBase64Image(home.HOME0001) ?`data:image/png;base64,${home.HOME0001}` : getAssets.home.main_banner}) no-repeat` }}>
        <div className="header">
          <header>
            <HeaderMenu />
          </header>
          <div className="fixed-height"></div>
        </div>
        <div className="grid header-text">
          <div className="col-12 lg:col-8 md:col-8">
            <div className="header-text-box">
              <h1>{home?.HOME0002}</h1>
              <p>{home?.HOME0003}</p>
              <PR.Button
                label={home?.HOME0004}
                className="goto-plans-button p-button-rounded"
                onClick={handleClick}
              />
            </div>
          </div>
        </div>
      </section>

      {/* Options Section */}
      <section className="options-section">
        <div className="grid">
          <div className="col-12 lg:col-4 md:col-4">
            <div className="option-box">
              <PR.Image src={isBase64Image(home.HOME0007) ? `data:image/png;base64,${home.HOME0007}` : getAssets.home.panel1} alt="Instant Activation" />
              <h2>{home?.HOME0005}</h2>
              <p>{home?.HOME0006}</p>
            </div>
          </div>
          <div className="col-12 lg:col-4 md:col-4">
            <div className="option-box">
              <PR.Image src={isBase64Image(home.HOME0010) ? `data:image/png;base64,${home.HOME0010}` : getAssets.home.panel2} alt="Attractive Rates" />
              <h2>{home?.HOME0008}</h2>
              <p>{home?.HOME0009}</p>
            </div>
          </div>
          <div className="col-12 lg:col-4 md:col-4">
            <div className="option-box">
              <PR.Image src={isBase64Image(home.HOME0013) ? `data:image/png;base64,${home.HOME0013}` : getAssets.home.panel3} alt="Global Coverage" />
              <h2>{home?.HOME0011}</h2>
              <p>{home?.HOME0012}</p>
            </div>
          </div>
        </div>

        {/* SmartPath Section */}
        <section className="smartpath-section">
          <h2>{home?.HOME0049}</h2>
          <p>{home?.HOME0050}</p>
          <p className="mt-5">{home?.HOME0017}</p>
          <ul className="list-none">
            <li className="left right"><span>{home?.HOME0043}</span></li>
            <li className="left right"><span className="custom-width">{home?.HOME0044} <br/> {home?.HOME0042}</span></li>
            <li className="left right"><span>{home?.HOME0047}</span></li>
            <li className="left right"><span>{home?.HOME0046}</span></li>
            <li className="left right"><span>{home?.HOME0048}</span></li>
          </ul>

        </section>
        {/* #6d69fdfc */}
      </section>

      {/* unlock world Section */}
      {getAssets.home.unlockworld_section_display &&
        <section className="offer-section connectivity-section">
          <div className="grid grid-nogutter column-reverse align-items-center">
            <div className="col-12 lg:col-6 md:col-6 text-center">
              <div className="option-box">
                <PR.Image
                  className="hand-iphone-img"
                  src={isBase64Image(home.HOME0014) ? `data:image/png;base64,${home.HOME0014}` : getAssets.home.unlock_world}
                  alt="Connectivity"
                />
              </div>
            </div>
            <div className="col-12 lg:col-6 md:col-6">
              <div className="option-box">
                <h2>{home?.HOME0015}</h2>
                <p>{home?.HOME0016}</p>
                {/* <PR.Button label={t("home.createAccount")} className="choose-plans-button" onClick={handleClickSignup} rounded /> */}
              </div>
            </div>
          </div>
        </section>}

      {/* Plans Section */}
      {getAssets.home.plans_section_display && <section className="plan-section" ref={ref}>
        <div className="grid align-items-center">
          <h2>{labels?.LBL0296}</h2>
          <PR.Image src={esimIcon} className="esim-icon" alt="eSIM Icon" />
        </div>
        <div className="card">
          <div className="bundles-section">
            <BundlesList />
          </div>
        </div>
      </section>}

      {/* Benefits Section */}
      {getAssets.home.benefits_section_display && <section className="benefits-section">
        <div className="grid">
          <div className="col-12 lg:col-6 md:col-6">
            <div className="option-box">
              <h2>{labels?.LBL0139}</h2>

              <div className="flex align-items-center gap-4 mb-5">
                <div className="benefits-icon">
                  <PR.Image
                    className="hand-iphone-img"
                    src={isBase64Image(home.HOME0020) ? `data:image/png;base64,${home.HOME0020}` : getAssets.home.benifit_section.multi_network_connectivity}
                    alt="Multi-Network Connectivity"
                  />
                </div>
                <div className="benefits-text">
                  <h3>{home?.HOME0018}</h3>
                  <p>{home?.HOME0019}</p>
                </div>
              </div>

              <div className="flex align-items-center gap-4 mb-5">
                <div className="benefits-icon">
                  <PR.Image
                    className="hand-iphone-img"
                    src={isBase64Image(home.HOME0023) ? `data:image/png;base64,${home.HOME0023}` : getAssets.home.benifit_section.fast_connections}
                    alt="Fast Connections"
                  />
                </div>
                <div className="benefits-text">
                  <h3>{home?.HOME0021}</h3>
                  <p>{home?.HOME0022}</p>
                </div>
              </div>

              <div className="flex align-items-center gap-4 mb-5">
                <div className="benefits-icon">
                  <PR.Image
                    className="hand-iphone-img"
                    src={isBase64Image(home.HOME0026) ? `data:image/png;base64,${home.HOME0026}` : getAssets.home.benifit_section.support_24_7}
                    alt="Support 24x7"
                  />
                </div>
                <div className="benefits-text">
                  <h3>{home?.HOME0024}</h3>
                  <p>{home?.HOME0025}</p>
                </div>
              </div>

              <div className="flex align-items-center gap-4 mb-5">
                <div className="benefits-icon">
                  <PR.Image
                    className="hand-iphone-img"
                    src={isBase64Image(home.HOME0029) ? `data:image/png;base64,${home.HOME0029}` : getAssets.home.benifit_section.easy_installation}
                    alt="Easy Installation"
                  />
                </div>
                <div className="benefits-text">
                  <h3>{home?.HOME0027}</h3>
                  <p>{home?.HOME0028}</p>
                </div>
              </div>

              <div className="flex align-items-center gap-4">
                <div className="benefits-icon">
                  <PR.Image
                    className="hand-iphone-img"
                    src={isBase64Image(home.HOME0032) ? `data:image/png;base64,${home.HOME0032}` : getAssets.home.benifit_section.best_pricing_plans}
                    alt="Best Pricing Plans"
                  />
                </div>
                <div className="benefits-text">
                  <h3>{home?.HOME0030}</h3>
                  <p>{home?.HOME0031}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 lg:col-6 md:col-6 text-center">
            <div className="option-box">
              <PR.Image
                className="benefits-img"
                src={isBase64Image(home.HOME0033) ? `data:image/png;base64,${home.HOME0033}` : getAssets.home.benifit_section.right_image}
                alt="Benefits"
              />
            </div>
          </div>
        </div>
      </section>}

      {/* Partners Section */}
      {getAssets.home.partners_section_display && <section className="partners-section">
        <div className="grid align-items-center">
          <div className="col-12 lg:col-7 md:col-7">
            <div className="partners-text-box flex align-items-center justify-content-center">
              <div>
                <h2>{home?.HOME0034}</h2>
                <p>{home?.HOME0035}</p>
                <Link className="view-all-button" to='/partners'>{home?.HOME0036}</Link>
              </div>
            </div>
          </div>
          <div className="col-12 lg:col-5 md:col-5 text-center">
            <div className="option-box">
              <PR.Image
                className="benefits-img"
                src={isBase64Image(home?.HOME0037) ? `data:image/png;base64,${home?.HOME0037}` : getAssests.home.partner?.partner_image}
                alt="Benefits"
              />
            </div>
          </div>
        </div>
      </section>}

      {/* FAQ Section */}
      {getAssets.home.faq_section_display && <section className="faq-section">
        <div className="grid align-items-center">
          <div className="col-12 lg:col-10 md:col-10 md:col-offset-1 lg:col-offset-1">
            <h2>{labels?.LBL0136}</h2>
            {faq &&
              faq.length > 0 &&
              faq.slice(0, 1).map((item, i) => (
                <PR.Accordion key={i}>
                  {item.QA.slice(0, 4).map((qa, j) => (
                    <PR.AccordionTab header={qa.question} key={j}>
                      {qa.answer.map((an, h) => (
                        <div key={h}>
                          {an.content_heading && (
                            <p><strong>{an.content_heading}</strong></p>
                          )}
                          {an.content && (
                            <p>{an.content}</p>
                          )}
                          {an.olist?.length > 0 && (
                            <ol>
                              {an.olist.map((anl, nh) => (
                                <li key={nh}>{anl}</li>
                              ))}
                            </ol>
                          )}
                          {an.ulist?.length > 0 && (
                            <ul>
                              {an.ulist.map((anl, nh) => (
                                <li key={nh}>{anl}</li>
                              ))}
                            </ul>
                          )}
                        </div>
                      ))}
                    </PR.AccordionTab>
                  ))}
                </PR.Accordion>
              ))}
          </div>
        </div>
        <div className="text-center faq-custom-margin">
          <Link to="/faq" className="view-all-button">{labels?.LBL0324}</Link>
        </div>
      </section>}

      {/* Form Section */}
      <section className="about-partners-section" ref={ref}>
        <div className="grid align-items-center">
          <div className="col-12 lg:col-5 md:col-6">
            <div className="partners-text-box flex justify-content-center">
              <div>
                <h2>{contact?.CON0001}</h2>
                <p>{contact?.CON0002} <Link to={`mailto:${globalConfig.partnersEmail}`}>{globalConfig.partnersEmail}</Link></p>
                <ContactForm formType={"home"} toast={toast} />
              </div>
            </div>
          </div>
          <div className="col-12 lg:col-7 md:col-6 text-center">
            <div className="option-box">
              <PR.Image className="benefits-img" src={getAssets.feature.contact_featureImage} alt="Benefits" />
            </div>
          </div>
        </div>
      </section>
      <PR.Toast ref={toast} position='top-right' />
    </HelmetProvider>
  );
};

export default Home;
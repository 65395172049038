import React, { useState, useMemo } from "react";
import * as PR from "../../prime-modules/index";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { login } from '../../services/api';
import { authActions } from "../../store/auth";
import { esimIccidActions } from '../../store/esim';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
// import GoogleLoginComponent from "./GoogleLogin";
// import AppleLoginComponent from "./AppleLogin";
import { trimFormData, scrollTop } from "../../utils/reuse";
import { getAllCountries } from "../../genericFunctions/GetAllCountries";
import { useToast } from "../../context/ToastContext";

const Login = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { labels,validations, showToast,showMessage } = useToast();
    const navigate = useNavigate();
    const [disableBtn, setDisableBtn] = useState(false);
    const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo);
    const headers = useMemo(() => {
        return { afid: affiliateTrackingData };
    }, [affiliateTrackingData]);
    const [apiMsg, setApiMsg] = useState('');

    const initialValues = {
        email: "",
        password: "",
    };
    const validationSchema = () => {
        return Yup.object().shape({
            email: Yup.string().trim()
                .required("VLD0005")
                .email("VLD0006"),
            password: Yup.string().trim().required("VLD0001")
        });
    }
    const handleSubmit = formData => {
        setApiMsg('');
        setDisableBtn(true);
        const getLoginResponse = (response) => {
            if (response.result === "SUCCESS") {
                const callingCode = response.data.phone?.callingCode ? response.data.phone?.callingCode : '';
                getAllCountries(showToast, dispatch, callingCode, '');
                props.renderFormState(false, "")
                location.pathname === "/redeem-voucher" ? navigate("/redeem-voucher#topup"): navigate("/");   
                dispatch(authActions.onLogin(response.data));
                dispatch(esimIccidActions.deleteEsim());
                scrollTop();
            } else {
                const error = response.error;
                const errorMsg = (error.errorMsg) ? error.errorMsg : error.summary;
                setApiMsg(errorMsg);
            }
            setDisableBtn(false);
        }
        const trimmedFormData = trimFormData(formData);
        login(trimmedFormData, headers, dispatch, getLoginResponse);
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    });

    const hideApiErrors = (e) => {
        setApiMsg('');
    }

    return (
        <>
            <div className="form-section">
                <div className="text-right form-top-link">
                    <p> {labels?.LBL0070} <Link onClick={() => props.renderFormState(true, "signup")}>{labels?.LBL0226}</Link></p>
                </div>
                <div className="form">
                    <h3>{labels?.LBL0068}</h3>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="input-field">
                            <PR.InputText id="login-email" name="email" className="w-12" placeholder={labels?.LBL0037} value={formik.values.email} onChange={e => { formik.handleChange(e); hideApiErrors(e); }} onBlur={formik.handleBlur} autoComplete="off" />
                            {formik.errors.email && formik.touched.email
                                ? <div className='error-message'>{validations[formik.errors.email]}</div>
                                : ''
                            }
                        </div>
                        <div className="input-field">
                            <PR.Password feedback={false} id="password" name="password" className="w-12" toggleMask autoComplete="off" placeholder={labels?.LBL0071} value={formik.values.password} onChange={e => { formik.handleChange(e); hideApiErrors(e); }} onBlur={formik.handleBlur} />
                            {formik.errors.password && formik.touched.password
                                ? <div className='error-message'>{validations[formik.errors.password]}</div>
                                : ''
                            }
                            {apiMsg && apiMsg !== '' &&
                                <div className="col-12 md:col-12">
                                    <div className='error-message'>{showMessage(apiMsg)}</div>
                                </div>
                            }
                        </div>
                        <Link onClick={() => props.renderFormState(true, 'forgotpwd')} className="forgot-btn">{labels?.LBL0033}</Link>
                        <PR.Button label={labels?.LBL0068} type='submit' disabled={disableBtn} className="login-btn" />
                        {/* <span className="or-span">{t("login.or")}</span>
                        <div className="flex mobile-flex gap-3">
                            <GoogleLoginComponent displayName={t("login.googleSignIn")} renderFormState={props.renderFormState} type={"login"} />
                            <AppleLoginComponent displayName={t("login.appleSignIn")} renderFormState={props.renderFormState} />
                        </div> */}
                    </form>
                </div>
                <div className="form-footer">
                    <p>{labels?.LBL0078} <Link to="/privacy-policy" onClick={() => props.renderFormState(false, "")}>{labels?.LBL0075}</Link> {labels?.LBL0077} <Link to="/terms-and-conditions" onClick={() => props.renderFormState(false, "")}>{labels?.LBL0076}.</Link></p>
                </div>
            </div>
        </>
    );
}

export default Login;
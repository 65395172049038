import React, { useRef } from "react";
import "../about-us/AboutUs.scss";
import * as PR from "../../prime-modules/index";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { globalConfig } from "../../constants";
import SubHeader from "../../components/SubHeader";
import { useToast } from "../../context/ToastContext";
import getAssets from '../../assets/data/brandingAssets.json';

const AboutUs = () => {
  const { aboutus, isBase64Image } = useToast();
  const toast = useRef();

  return (
     <HelmetProvider> 
      <Helmet>
          <meta charSet="utf-8" />
          <title>{globalConfig.domainName} | Abut Us</title>
      </Helmet>

      {/* Header Section */}
      <div className="about-page">
        <SubHeader header={aboutus?.AB0001} subText={aboutus?.AB0002}/>
      </div>
      <PR.Toast ref={toast} position='top-right' />
      {getAssets.about_us.aboutus_section_display && <section className="about-blocks-section">
        <div className="custom-container">
          <div className="grid align-items-center custom-margin">
              <div className="col-12 lg:col-6 md:col-6">
                <PR.Image src={isBase64Image(aboutus.AB0003) ? `data:image/png;base64,${aboutus.AB0003}`: getAssets.about_us.unlock_world} className="about-img" alt="Unlock a World of Seamless Connectivity"/>
              </div>
              <div className="col-12 lg:col-6 md:col-6 right">
                <h2>{aboutus?.AB0004}</h2>
                <p>{aboutus?.AB0005}</p>
              </div>
          </div>
          <div className="grid align-items-center custom-margin column-reverse">
              <div className="col-12 lg:col-6 md:col-6 right">
                <h2>{aboutus?.AB0006}</h2>
                <p>{aboutus?.AB0007}</p>
              </div>
              <div className="col-12 lg:col-6 md:col-6">
                <PR.Image src={isBase64Image(aboutus.AB0008) ? `data:image/png;base64,${aboutus.AB0008}`: getAssets.about_us.instant_activation} className="about-img" alt="Instant Activation"/>
              </div>
          </div>
          <div className="grid align-items-center custom-margin">
              <div className="col-12 lg:col-6 md:col-6">
                <PR.Image src={isBase64Image(aboutus.AB0009) ? `data:image/png;base64,${aboutus.AB0009}`: getAssets.about_us.global_coverage} className="about-img" alt="Global Coverage"/>
              </div>
              <div className="col-12 lg:col-6 md:col-6 right">
                <h2>{aboutus?.AB0010}</h2>
                <p>{aboutus?.AB0011}</p>
              </div>
          </div>
          <div className="grid align-items-center custom-margin column-reverse">
              <div className="col-12 lg:col-6 md:col-6 right">
                <h2>{aboutus?.AB0012}</h2>
                <p>{aboutus?.AB0013}</p>
              </div>
              <div className="col-12 lg:col-6 md:col-6">
                <PR.Image src={isBase64Image(aboutus.AB0014) ? `data:image/png;base64,${aboutus.AB0014}`: getAssets.about_us.attractive_rates} className="about-img" alt="Attractive Rates"/>
              </div>
          </div>
        </div>
      </section>}
    </HelmetProvider>
  );
};

export default AboutUs;
import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";
import * as PR from "../../prime-modules/index";
import "../page-not-found/PageNotFound.scss";
import { globalConfig } from "../../constants";
import getAssets from '../../assets/data/brandingAssets.json';
import { useToast } from "../../context/ToastContext";

const PageNotFound = () => {
  const { labels, isBase64Image,general  } = useToast();
  return (
    <HelmetProvider>
      <Helmet>
          <meta charSet="utf-8" />
          <title>{globalConfig.domainName} | PAGE NOT FOUND</title>
      </Helmet>

      {/* Header Section */}
      <section className="header-section maintenance-section page-not-found">
        <div className="header">
          <header>
            <Link to="/"><PR.Image src={isBase64Image(general?.GEN0003) ? `data:image/png;base64,${general?.GEN0003}` : getAssets.logo.logo_light} className="esim-icon" alt="eSIM Icon" /></Link>
          </header>
        </div>
        <div className="grid header-text">
          <div className="col-12 md:col-10 lg:col-7">
            <div className="header-text-box">
              <h1>404 Error <span>{labels?.LBL0156}</span></h1>
              <p>{labels?.LBL0157}</p>
              <Link to="/" className="go-to-home"><i className="pi pi-chevron-left"></i> {labels?.LBL0158}</Link>
            </div>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default PageNotFound;
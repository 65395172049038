import React, { useCallback, useEffect, useState } from 'react';
import * as PR from '../../prime-modules/index';
import { useTranslation } from 'react-i18next';
const SelectLanguageDropDown = () => {
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(() => {
        const savedLanguage = localStorage.getItem("language");
        return savedLanguage ? savedLanguage : i18n.language?.split('-')?.[0];
    });
    const languages = [
        { label: 'English', value: 'en' },
        { label: 'Spanish', value: 'es' },
        { label: 'Portuguese', value: 'pt' },
    ];
    const changeLanguage = useCallback((lang) => {
        const selectedLanguage = lang.value
        setLanguage(selectedLanguage);
        localStorage.setItem("language",selectedLanguage);
        i18n.changeLanguage(selectedLanguage);
    }, [i18n]);

    useEffect(() => {
        const defaultLang = i18n.language.split('-')[0];
        i18n.changeLanguage(defaultLang);
        setLanguage(defaultLang);
    }, [i18n])

    return (
        <PR.Dropdown
            value={language}
            options={languages}
            optionLabel="label"
            optionValue="value"
            onChange={changeLanguage}
            className='new-1'
        />
    );
}

export default SelectLanguageDropDown;
import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";
import * as PR from "../../prime-modules/index";
import { activationIcon, dashboardIcon, investmentIcon, businessDealImg, stepsImg1, stepsImg2, stepsImg3, airlineIcon, travelIcon, ukTravelIcon } from "../../assets/images";
import HeaderMenu from "../../components/layout/HeaderMenu";
import "../home/Home.scss";
import "../affiliate/Affiliate.scss";
import { useDispatch, useSelector } from "react-redux";
import { scrollToBundlesActions } from "../../store/scrollToBundles";
import { envConfig } from "../../constants";
import { globalConfig } from '../../constants';
import { useToast } from "../../context/ToastContext";

const Affiliate = () => {
  const { affiliate } = useToast();
  const dispatch = useDispatch();
  const scrollToBundlesState = useSelector(state => state.scrollToBundles.scrollToBundlesState);

  useEffect(() => {
    if(scrollToBundlesState) {
      dispatch(scrollToBundlesActions.desleteSrollToBundles());
    }
  }, [scrollToBundlesState, dispatch]);

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{globalConfig.domainName} | Affiliate</title>
      </Helmet>

      {/* Header Section */}
      <section className="header-section affiliate-header-section">
        <div className="header">
          <header>
            <HeaderMenu />
          </header>
          <div className="fixed-height"></div>
        </div>
        <div className="grid header-text">
          <div className="col-12 lg:col-8 md:col-8">
            <div className="header-text-box">
              <h1>{affiliate?.AFF0002}</h1>
              <p>{affiliate?.AFF0003}</p>
              <Link
                to={envConfig.REACT_APP_AFFILIATE_DOMAIN_URL + '/signup'}
                target='_blank'
                className="go-to-home"><b>{affiliate?.AFF0004}</b>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Options Section */}
      <section className="options-section affiliate-options">
        <div className="grid">
          <div className="col-12 lg:col-4 md:col-4">
            <div className="option-box">
              <PR.Image src={activationIcon} alt="Instant Activation" />
              <h2>{affiliate?.AFF0005}</h2>
              <p>{affiliate?.AFF0006}</p>
            </div>
          </div>
          <div className="col-12 lg:col-4 md:col-4">
            <div className="option-box">
              <PR.Image src={dashboardIcon} alt="Attractive Rates" />
              <h2>{affiliate?.AFF0008}</h2>
              <p>{affiliate?.AFF0009}</p>
            </div>
          </div>
          <div className="col-12 lg:col-4 md:col-4">
            <div className="option-box">
              <PR.Image src={investmentIcon} alt="Global Coverage" />
              <h2>{affiliate?.AFF0011}</h2>
              <p>{affiliate?.AFF0012}</p>
            </div>
          </div>
        </div>
      </section>

      {/* Affiliate Program Section */}
      <section className="offer-section affiliate-program-section">
        <div className="grid column-reverse">
          <div className="col-12 lg:col-6 md:col-6 text-center">
            <div className="option-box">
              <PR.Image
                className="affiliate-program-image"
                src={businessDealImg}
                alt="Connectivity"
              />
            </div>
          </div>
          <div className="col-12 lg:col-6 md:col-6">
            <div className="option-box">
                <h2>{affiliate?.AFF0015}</h2>
                <p>{affiliate?.AFF0016}</p>
                <Link
                to={envConfig.REACT_APP_AFFILIATE_DOMAIN_URL + '/signup'}
                target='_blank'
                className="choose-plans-button"><b>{affiliate?.AFF0017}</b>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Steps Section */}
      <section className="plan-section steps-section">
        <div className="align-items-center">
          <h2>{affiliate?.AFF0018}</h2>
          <p>{affiliate?.AFF0019}</p>
          <div className="card">
              <div className="grid">
                  <div className="col-12 lg:col-4 md:col-4">
                    <div className="relative">
                      <div className="steps-box">
                        <div className="steps-icon">
                          <PR.Image src={stepsImg1} alt="Step One" /> 
                        </div>
                        <h3>
                          <p>01</p>
                          {affiliate?.AFF0021}
                        </h3>
                      </div>
                      <div className="stepsnumber"></div>
                    </div>
                  </div>
                  <div className="col-12 lg:col-4 md:col-4">
                    <div className="relative">
                      <div className="steps-box">
                        <div className="steps-icon secondary-color">
                          <PR.Image src={stepsImg2} alt="Step Two" />
                        </div>
                        <h3>
                          <p className="secondary-color">02</p>
                          {affiliate?.AFF0023}
                        </h3>
                      </div>
                        <div className="stepsnumber secondary-color"></div>
                    </div>
                  </div>
                  <div className="col-12 lg:col-4 md:col-4">
                      <div className="relative">
                        <div className="steps-box">
                          <div className="steps-icon">
                            <PR.Image src={stepsImg3} alt="Step Three" />
                          </div>
                          <h3>
                            <p>03</p>
                            {affiliate?.AFF0025}
                          </h3>
                        </div>
                        <div className="stepsnumber"></div>
                      </div>
                  </div>
              </div>
          </div>
          <p>{affiliate?.AFF0026}</p>
        </div>
      </section>

      <section className="plan-section sales-info-section">
        <div className="align-items-center">
          <h2>{affiliate?.AFF0027}</h2>
          <div className="card info-box">
              <div className="div-row first-row">
                 <div className="left">
                    <ul className="flex align-items-center">
                      <li>
                        <PR.Image
                          src={airlineIcon}
                          alt="Connectivity"
                        />
                      </li>
                      <li>
                        <h3>{affiliate?.AFF0059}</h3>
                        <p>{affiliate?.AFF0060}</p>
                      </li>
                    </ul>
                 </div>
                 <div className="right">
                    <p>58,000 {globalConfig.domainName} {affiliate?.AFF0061}</p>
                    <p><span>$88,000 / {affiliate?.AFF0062}</span></p>
                 </div>
              </div>
              <div className="div-row">
                 <div className="left">
                    <ul className="flex align-items-center">
                      <li>
                        <PR.Image
                          src={travelIcon}
                          alt="Connectivity"
                        />
                      </li>
                      <li>
                        <h3>{affiliate?.AFF0063}</h3>
                        <p>{affiliate?.AFF0064}</p>
                      </li>
                    </ul>
                 </div>
                 <div className="right">
                    <p>20,000 {globalConfig.domainName} {affiliate?.AFF0061}</p>
                    <p><span>$60,000 / {affiliate?.AFF0062}</span></p>
                 </div>
              </div>
              <div className="div-row">
                 <div className="left">
                    <ul className="flex align-items-center">
                      <li>
                        <PR.Image
                          src={ukTravelIcon}
                          alt="Connectivity"
                        />
                      </li>
                      <li>
                        <h3>{affiliate?.AFF0065}</h3>
                        <p>{affiliate?.AFF0066}</p>
                      </li>
                    </ul>
                 </div>
                 <div className="right">
                    <p>350 {globalConfig.domainName} {affiliate?.AFF0061}</p>
                    <p><span>$950 / {affiliate?.AFF0062}</span></p>
                 </div>
              </div>
              <p className="text-center info_p">{affiliate?.AFF0067}</p>
          </div>
        </div>
      </section>

      {/* Partners Section */}
      <section className="partners-section getting-started-section">
        <div className="grid align-items-center">
          <div className="col-12 lg:col-7 md:col-7">
            <div className="partners-text-box getting-started-box">
              <div>
                <h2>{affiliate?.AFF0028}</h2>
                <p>{affiliate?.AFF0029}<br/> {affiliate?.AFF0030}</p>
                <ul>
                  <li><span className="number">1</span></li>
                  <li>
                    <h3>{affiliate?.AFF0031}</h3>
                    <p>{affiliate?.AFF0032}</p>
                  </li>
                </ul>
                <ul>
                  <li><span className="number">2</span></li>
                  <li>
                    <h3>{affiliate?.AFF0033}</h3>
                    <p>{affiliate?.AFF0034}</p>
                  </li>
                </ul>
                <ul>
                  <li><span className="number">3</span></li>
                  <li>
                    <h3>{affiliate?.AFF0035}</h3>
                    <p>{affiliate?.AFF0036}</p>
                    <Link className="view-all-button" to={envConfig.REACT_APP_AFFILIATE_DOMAIN_URL + '/signup'} target="_blank">{affiliate?.AFF0037}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 lg:col-5 md:col-5">
            <div className="getting-started-right-box">
              <div className="info">
                <h2>{affiliate?.AFF0039}</h2>
                <Link className="view-all-button btn-white" to={envConfig.REACT_APP_AFFILIATE_DOMAIN_URL + '/signup'} target="_blank">{affiliate?.AFF0040}</Link>
                <Link className="view-all-button faq-btn" to='/faq'>{affiliate?.AFF0041}</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

    </HelmetProvider>
  );
};

export default Affiliate;
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as PR from '../prime-modules/index';
import * as Yup from 'yup';
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import { verifyemail } from "../services/api";
import { authActions } from "../store/auth";
import { useToast } from "../context/ToastContext";

const VerifyEmail = (props) => {
  const { labels,validations, showToast } = useToast();
  const dispatch = useDispatch();
  const idPath = useSelector(state => state.auth.idPath);
  const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo);
  const isLoggedIn = useSelector(state =>  state.auth.isLoggedIn);
  const [userIdCleared, setUserIdCleared] = useState(false);
  const location = useLocation();
  const [disableBtn, setDisableBtn] = useState(false);
  const headers = useMemo(() => {
      return {afid: affiliateTrackingData};
  }, [affiliateTrackingData]);

  useEffect(() => {
    if (!idPath.userId && !userIdCleared) {
      showToast("error",labels?.LBL0306)
    }
  }, [location, idPath.userId, userIdCleared, labels,showToast])

  const formInitialValues = {
    otp: '',
  };

  const validationSchema = () => {
    return Yup.object().shape({
      otp: Yup.string().trim()
        .required('LBL0049')
        .min(6, 'LBL0050')
        .max(6, 'LBL0050')
        .matches(/^[0-9\b]+$/, 'LBL0051')
    });
  }

  const submitHandler = (formData) => {
    setDisableBtn(true);
    const getResponse = (response) => {
      if (response.result === "SUCCESS") {
        showToast('success', labels?.LBL0284)
        formik.resetForm();
        setUserIdCleared(true)
        setTimeout(() => {
          dispatch(authActions.deleteStoreIdPath());
          props.renderFormState(!isLoggedIn, (isLoggedIn ? "" : "login"))
        }, 2000);
      } else {
        const error = response.error;
        const errorMsg = (error.errorMsg) ? error.errorMsg : error.summary;
        showToast(error.severity, errorMsg)
      }
      setDisableBtn(false);
    }

    const otpVal = formData.otp.trim();
    const otpRequest = {
      userId: +idPath.userId,
      code: otpVal
    }

    const apiUrl = (idPath.pathName === "verify-email") ? 'verifyemail' : 'updateemail';
    verifyemail(otpRequest, apiUrl, headers, dispatch, getResponse)
  }
  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: validationSchema,
    onSubmit: submitHandler
  });

  return (
    <>
      <div className="form-section forgot-password-section">
          <div className="form">
              <h3>{labels?.LBL0045}</h3>
              <p>{labels?.LBL0046}</p>
              <form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="input-field">
                    <PR.InputText id="otp" name="otp" placeholder={labels?.LBL0048} className="w-12" value={formik.values.otp} onChange={formik.handleChange} onBlur={formik.handleBlur} autoComplete="off" />
                    {formik.errors.otp && formik.touched.otp
                      ? <div className='error-message'>{validations[formik.errors.otp]}</div>
                      : ''
                    }
                  </div> 
                  <PR.Button label={labels?.LBL0047} type='submit' disabled={disableBtn} className="login-btn signup-btn" />
              </form>
          </div>
      </div>
    </>
  );
};

export default VerifyEmail;
import { getCountryInfo } from '../services/api';
import { allCountriesActions } from '../store/allCountries';

export const fetchCountryInfo = (params, dispatch, setCountry, showToast) => {
  const request = 'countries=' + params.countries + '&info=' + params.info;
  getCountryInfo(request, dispatch, (response) => {
    if (response.result === 'SUCCESS') {
      const responseData = response.data && response.data ? response.data : [];
      const countriesData = responseData?.map((list) => ({ label: list.countryName, value: list.iso, provider: list.serviceProviders }));
      const sortedCountries = countriesData.sort((a, b) => a.label.localeCompare(b.label));
      setCountry(sortedCountries);
    } else {
      const error = response.error;
      const errorMsg = (error.errorMsg) ? error.errorMsg : error.summary;
      showToast(error.severity, errorMsg)
    }
  });
};

  // Get list of countries
  export const getAllCountries = (showToast, dispatch, callingCode, countryCode) => {
    const request = "countries=&info=";
    const getResponse = (response) => {
        if (response.result === "SUCCESS") {
            const responseData =
                response.data && response.data
                    ? response.data : [];
                    const filterCode = responseData.filter(country => country?.callingCode === callingCode);
            dispatch(allCountriesActions.setAllCountries(responseData));
            dispatch(allCountriesActions.setPhoneCountry(filterCode[0]));
            const filterDefaultCallingCode = responseData.filter(country => country?.iso === countryCode);
            dispatch(allCountriesActions.setDefaultCallingCode(filterDefaultCallingCode[0]?.callingCode));
        } else {
            const error = response.error;
            const errorMsg = error.errorMsg ? error.errorMsg : error.summary;
            showToast(error.severity, errorMsg)
        }
    };
    getCountryInfo(request, dispatch, getResponse);
};

export const getPhoneObj = (phoneValue, isAuth, userData, formData) => {
  const loggedInPhone = (isAuth && userData.phone?.localPhoneNumber) ? userData.phone?.localPhoneNumber : "";
  const loggedInCallingCode = (isAuth && userData.phone?.callingCode) ? userData.phone?.callingCode : "";
  const phone = {
    localPhoneNumber: phoneValue ? phoneValue : formData?.callingCode ? "" : loggedInPhone,
    callingCode: formData?.callingCode ? formData?.callingCode : loggedInCallingCode
  }
  return phone;
};
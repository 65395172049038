import React, { useState } from 'react'
import { Link } from "react-router-dom";
import * as PR from "../../prime-modules/index";
import { globalConfig } from '../../constants';
import getAssets from "../../assets/data/brandingAssets.json";
import { useToast } from '../../context/ToastContext';

const Footer = () => {
  const { labels, isBase64Image,general } = useToast();
  const [year] = useState(new Date().getFullYear());

  return (
    <>
      <footer>
        <div className='grid grid-nogutter align-items-center justify-content-between copyrights-div'>
        <div className='right'>
            <PR.Image className="logo" src={isBase64Image(general?.GEN0002) ? `data:image/png;base64,${general?.GEN0002}` : getAssets.logo.logo_dark} alt="logo" width='145'/>
          </div>
          <p>&copy;{year} <Link to="/">{globalConfig.domainName}.</Link> {labels?.LBL0065}</p>
          
        </div>
        <PR.ScrollTop className='scroll-to-top' icon="pi pi-arrow-up" />
      </footer>
    </>
  )
}
export default Footer
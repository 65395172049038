import * as PR from "../prime-modules/index";

export const unlimitedPlanUsageAsterisk = (dataAmountForDisplay,labels) => {

    return dataAmountForDisplay === "Unlimited" ? <>
      <PR.Tooltip target=".deviceDetails" position="bottom" baseZIndex={99999}>
      <p className="tooltip-block">{labels?.LBL0336}</p>
      </PR.Tooltip>
      <sup className="deviceDetails asterisk-unlimited" >*</sup>
    </> : ""
  }

import { createSlice } from '@reduxjs/toolkit';
import assets from '../assets/data/assets.json'
export const messageCatalogSlice = createSlice({
    name: 'catalogIds',
    initialState:{activelang:assets},
    reducers: {
      
        setLanguageIntial(state,actions){
            const{k,v} =actions.payload;
            state.activelang = {...state.activelang,...v};
            state[k] = {...state[k],...v};
        }
    }
})

export const {setLanguageIntial} = messageCatalogSlice.actions;
export default messageCatalogSlice.reducer;

import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from '@stripe/react-stripe-js';
import * as PR from "../../prime-modules/index";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { LoadScript, Autocomplete } from "@react-google-maps/api";
import { envConfig, globalConfig } from "../../constants";
import { handleFirstNameChange, handleLastNameChange, trimFormData, renderPrice, displayDefaultPhone} from "../../utils/reuse";
import { cartFilterActions } from "../../store/cart";
import { stripePayment } from "../../services/api";
import { fetchCountryInfo, getPhoneObj } from '../../genericFunctions/GetAllCountries';
import { AutocompleteAddressSplit } from "../../utils/AutoCompleteAddressSplit";
import { cardElementstyles, cardNumberstyles } from "./utils";
import { v4 as uuidv4 } from 'uuid';
import CustomPhoneNumber from "../../components/CustomPhoneNumber";
import { parsePhoneNumber } from 'react-phone-number-input'
import { useToast } from "../../context/ToastContext";

const StripePayment = ( props ) => {
    const dispatch = useDispatch();
    const { labels,validations, showToast } = useToast();
    const getIPCountry = useSelector((state) => state.country.IPCountry).toLowerCase();
    const isAuth = useSelector(state => state.auth.isLoggedIn);
    const cartData = useSelector((state) => state.cart.catalogCart);
    const iccid = useSelector(state => state.esim.esimIccid);
    const userData = useSelector(state => state.auth.sessionData);
    const randomId = useSelector((state) => state.cart.randomId);
    const getDefaultCallingCode = useSelector((state) => state.allCountries.defaultCallingCode);
    const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo);
    const headers = useMemo(() => {
        return { sessionid: userData.sessionId, afid: affiliateTrackingData };
    }, [userData.sessionId, affiliateTrackingData]);
    const inputRef = useRef();
    const toast = useRef();
    const stripe = useStripe();
    const elements = useElements();
    const [stripePayError, setStripePayError] = useState('');
    const [payDisabled, setPayDisabled] = useState(false);
    const apiKey = envConfig.REACT_APP_GOOGLE_MAP_API_KEY;
    const libraries = globalConfig.libraries;
    const [display3DPopup, setDisplay3DPopup] = useState(false);
    const [auth3dSecureLink, setAuth3dSecureLink] = useState('');    
    const [country, setCountry] = useState([]);
    const [payLabel, setPayLabel] = useState('');
    const [phoneValue, setPhoneValue] = useState('');
    const [phoneError, setPhoneError] = useState("")
    const totalPayAmount = ('promoCode' in cartData) ? cartData.discountedPrice : cartData.price;
    useEffect(() => {
        const paymentLabel = labels?.LBL0103 + " " + renderPrice(totalPayAmount)
        setPayLabel(paymentLabel);
    }, [labels, totalPayAmount, stripe, elements])

    const createStripePayment = (paymentMethodID, formData) => {
        const getPaymentData = (response) => {
            setPayDisabled(false);
            if (response.result === "SUCCESS") {
                setPhoneValue('');
                paymentResponse(response.data);
            } else {
                props.paymentProps("", false)
                const error = response.error;
                const errorMsg = (error.errorMsg) ? error.errorMsg : error.summary;
                showToast(error.severity, errorMsg)
            }
        }

        if (paymentMethodID && paymentMethodID !== '') {
            const billingDetails = {
                address: formData.address,
                street: formData.street,
                city: formData.city,
                postCode: formData.postCode,
                country: formData.country
            }
            const customerDetails = {
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                phone: getPhoneObj(phoneValue, isAuth, userData, formData)
            }

            const getCartObj = { ...cartData };
            delete getCartObj.discountedPercentage;
            delete getCartObj.discountedPrice;
            delete getCartObj.dataAmountForDisplay;
            delete getCartObj.promoCodePrice;

            const dataPlans = [getCartObj];
            const deviceInfo = { device: globalConfig.deviceInfo };
            const paymentData = {
                contactInfo: customerDetails,
                billingAddress: billingDetails,
                orderSummary: {
                    dataPlans: dataPlans,
                    totalOrderAmount: cartData.price
                },
                paymentGateway: "Stripe",
                paymentMethodId: paymentMethodID,
                iccid: iccid,
                isEsimCompatible: globalConfig.isEsimCompatible,
                deviceInfo: deviceInfo,
                randomId: randomId ? randomId : uuidv4()
            }
            stripePayment(paymentData, headers, dispatch, getPaymentData)
        }
    };

    const paymentResponse = (payRes) => {
        if (payRes.status === "succeeded") {
            dispatch(cartFilterActions.removeRandomIdInPayment());
            on3DSecurePopupHide();
            props.paymentProps(payRes, false);
        } else if (payRes.status === "requires_action") {
            props.paymentProps("", true)
            setPayDisabled(true);
            setAuth3dSecureLink(payRes.authURL);
            setDisplay3DPopup(true);
        } else if (payRes.status === 'requires_payment_method') {
            props.paymentProps("", false)
            on3DSecurePopupHide()
            showToast('error', payRes.error)
        }
    }

    const formInitialValues = {
        firstName: isAuth ? userData.firstName : "",
        lastName: isAuth ? userData.lastName : "",
        email: isAuth ? userData.email : "",
        phone: displayDefaultPhone(isAuth, userData, envConfig, getIPCountry, getDefaultCallingCode),
        address: "",
        street: "",
        city: "",
        postCode: "",
        country: null
    };

    const validationSchema = () => {
        return Yup.object().shape({
            firstName: Yup.string().trim()
                .required("VLD0007")
                .min(3, "VLD0008")
                .max(20, "VLD0008"),
            lastName: Yup.string().trim()
                .required("VLD0009")
                .min(3, "VLD0010")
                .max(20, "VLD0010"),
                email: Yup.string().trim()
                .required("VLD0005")
                .email("VLD0006"),
            address: Yup.string().trim().required("VLD0025"),
            street: Yup.string().trim().required("VLD0026"),
            city: Yup.string().trim().required("VLD0027"),
            postCode: Yup.string().trim().required("VLD0028"),
            country: Yup.string().trim().nullable().required("VLD0029"),
        });
    }

    const handleSubmit = formData => {
        const trimmedFormData = trimFormData(formData);
        setStripePayError('');
        setPayDisabled(true);
        if (!stripe || !elements) {
            return;
        }
        // card number element as the card element
        const cardNumberElement = elements.getElement(CardNumberElement);
        if (cardNumberElement) {

            const customerName = (trimmedFormData.firstName + ' ' + trimmedFormData.lastName).trim();
            const billingDetails = {
                name: customerName,
                email: trimmedFormData.email,
                phone: trimmedFormData.phone,
            }

            stripe.createPaymentMethod(
                {
                    type: 'card',
                    card: cardNumberElement,
                    billing_details: billingDetails
                })
                .then(function (result) {
                    if (result.error) {
                        setPayDisabled(false);
                        setStripePayError(result.error.message);
                    } else {
                        props.paymentProps("", true)
                        setPayDisabled(true);
                        const payMethodID = result.paymentMethod.id;
                        if (payMethodID) {
                            createStripePayment(payMethodID, trimmedFormData);
                        } else {
                            props.paymentProps("", false)
                            setPayDisabled(false);
                            showToast('error', "PMTMSG-15")
                        }
                    }
                });
        }
    };

    const formik = useFormik({
        initialValues: formInitialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
        enableReinitialize: true
    });

    useEffect(() => {
        let isValidPhone = parsePhoneNumber(formik.values.phone);
        if(!isValidPhone?.nationalNumber) {
            setPhoneError(validations.VLD0021)
        } else if(isValidPhone?.nationalNumber.length <=6 || isValidPhone?.nationalNumber === undefined ) {
            setPhoneError(validations?.VLD0019)
        } else {setPhoneError("")}
    }, [formik.values.phone, validations])

    const onCardChange = (e) => {
        (e.error) ? setStripePayError(e.error.message) : setStripePayError('');
    }

    const on3DSecurePopupHide = () => {
        setDisplay3DPopup(false)
        setAuth3dSecureLink('');
        props.paymentProps("", false)
        setPayDisabled(false);
    }

    const handlePlaceChanged = () => {
        const place = inputRef.current.getPlace();
        if (place) {
            let components = {};
            place.address_components.map(e => {
                e.types.map(x => {
                    if (x === 'country') {
                        return components[x] = e.short_name;
                    } else {
                        return components[x] = e.long_name;
                    }
                });
                return components;
            });
            const addressList = AutocompleteAddressSplit(components);
            formik.setFieldValue("address", addressList.houseVal);
            formik.setFieldValue("street", addressList.streetVal);
            formik.setFieldValue("city", addressList.cityVal);
            formik.setFieldValue("postCode", addressList.postcodeVal);
            formik.setFieldValue("country", addressList.countryVal);
            formik.setFieldTouched('address', false, false)
            formik.setFieldTouched('street', false, false)
            formik.setFieldTouched('city', false, false)
            formik.setFieldTouched('postCode', false, false)
        }
    }

    const handleIframe = () => {
        const recieveIframeMsg = (event) => {
            if (event.origin === envConfig.REACT_APP_BASE_URL) {
                paymentResponse(event.data)
            }
        }
        window.addEventListener('message', recieveIframeMsg, false);
    }

    useEffect(() => {
        const fetchCountryData = async () => {
            const params = {
                countries: '',
                info: ''
            }
          await fetchCountryInfo(params, dispatch, setCountry, showToast);
        };
      
        fetchCountryData();
      }, [ dispatch, setCountry, toast, showToast]);

      const handleCountryBlur = () => {
        formik.setFieldTouched('country', true);
      };

    return (
        <>
            <div className="payment-form">
                <h3>{labels?.LBL0059}</h3>
                <div className="grid">
                   <div className="col-12">
                        <CardNumberElement className="card-number" options={{ style: { base: cardNumberstyles } }} onChange={onCardChange} />
                   </div>
                   <div className="col-12 lg:col-6 md:col-6">
                        <CardExpiryElement className="card-month" options={{ style: { base: cardElementstyles } }} onChange={onCardChange} />
                   </div>
                   <div className="col-12 lg:col-6 md:col-6">
                        <CardCvcElement className="card-month" options={{ style: { base: cardElementstyles } }} onChange={onCardChange} />
                   </div>
                </div>

                {stripePayError ? <div className='error-message mb-4 mt-2'>{stripePayError}</div> : ''}
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <h3>{labels?.LBL0307}</h3>
                    <div className="grid">
                        <div className="col-12 lg:col-6 md:col-6">
                            <PR.InputText id="firstName" name="firstName" value={formik.values.firstName} keyfilter={"alphanum"} onChange={(e) => handleFirstNameChange(e, formik)} onBlur={formik.handleBlur} placeholder={labels?.LBL0001} style={{ 'width': '100%' }} autoComplete="off" />
                            {formik.errors.firstName && formik.touched.firstName ? <div className='error-message'>{validations[formik.errors.firstName]}</div> : ''}
                        </div>
                        <div className="col-12 lg:col-6 md:col-6">
                            <PR.InputText id="lastName" name="lastName" value={formik.values.lastName} keyfilter={"alphanum"} onChange={(e) => handleLastNameChange(e, formik)} onBlur={formik.handleBlur} placeholder={labels?.LBL0002} style={{ 'width': '100%' }} autoComplete="off" />
                            {formik.errors.lastName && formik.touched.lastName ? <div className='error-message'>{validations[formik.errors.lastName]}</div> : ''}
                        </div>
                    </div>

                    <div className="grid">
                        <div className="col-12 lg:col-6 md:col-6">
                            <PR.InputText id="email" name="email" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder={labels?.LBL0010} style={{ 'width': '100%' }} autoComplete="off" disabled={isAuth} />
                            {formik.errors.email && formik.touched.email ? <div className='error-message'>{validations[formik.errors.email]}</div> : ''}
                        </div>
                        <div className="col-12 lg:col-6 md:col-6">
                            <CustomPhoneNumber formik={formik} labels={labels} setPhoneValue={setPhoneValue} setDisableBtn={setPayDisabled} phoneError={phoneError}/>
                        </div>
                    </div>

                    <h3>{labels?.LBL0060}</h3>
                    <LoadScript scriptjs={false} googleMapsApiKey={apiKey} libraries={libraries}>
                        <Autocomplete
                            onLoad={ref => inputRef.current = ref}
                            onPlaceChanged={handlePlaceChanged}>
                            <span className="p-input-icon-right" style={{ 'display': 'block' }}>
                                <i className="pi pi-map-marker" />
                                <PR.InputText id="addresstext" name="addresstext" placeholder={labels?.LBL0308} style={{ 'width': '100%' }} />
                            </span>
                        </Autocomplete>
                    </LoadScript>
                    <div className="grid">
                        <div className="col-12 lg:col-6 md:col-6">
                            <PR.InputText id="address" name="address" value={formik.values.address}
                                onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder={labels?.LBL0309} style={{ 'width': '100%' }} autoComplete="off" />
                            {formik.errors.address && formik.touched.address ? <div className='error-message'>{validations[formik.errors.address]}</div> : ''}
                        </div>
                        <div className="col-12 lg:col-6 md:col-6">
                            <PR.InputText id="street" name="street" placeholder={labels?.LBL0310} value={formik.values.street} onChange={formik.handleChange} onBlur={formik.handleBlur} style={{ 'width': '100%' }} autoComplete="off" />
                            {formik.errors.street && formik.touched.street ? <div className='error-message'>{validations[formik.errors.street]}</div> : ''}
                        </div>
                    </div>

                    <div className="grid">
                        <div className="col-12 lg:col-6 md:col-6">
                            <PR.InputText id="city" name="city" placeholder={labels?.LBL0311} value={formik.values.city} onChange={formik.handleChange} onBlur={formik.handleBlur} style={{ 'width': '100%' }} autoComplete="off" />
                            {formik.errors.city && formik.touched.city ? <div className='error-message'>{validations[formik.errors.city]}</div> : ''}
                        </div>
                        <div className="col-12 lg:col-6 md:col-6">
                            <PR.InputText id="postCode" name="postCode" value={formik.values.postCode} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder={labels?.LBL0312} style={{ 'width': '100%' }} autoComplete="off" />
                            {formik.errors.postCode && formik.touched.postCode ? <div className='error-message'>{validations[formik.errors.postCode]}</div> : ''}
                        </div>
                    </div>

                    
                    <PR.Dropdown
                        id="country"
                        name="country"
                        value={formik.values.country}
                        options={country}
                        onChange={formik.handleChange}
                        onHide={handleCountryBlur}
                        resetFilterOnHide
                        optionLabel="label"
                        optionValue="value"
                        showClear placeholder={labels?.LBL0023}
                        style={{ 'width': '100%' }}
                        filter
                        filterBy="label"
                    />
                    {formik.errors.country && formik.touched.country ? <div className='error-message'>{validations[formik.errors.country]}</div> : ''}
                    <PR.Button type="submit" label={payLabel} className="continue-button" disabled={payDisabled || phoneError} style={{ 'width': '100%' }} />
                </form>
            </div>

            <PR.Dialog header="" visible={display3DPopup} breakpoints={{ '960px': '85vw' }} style={{ width: '35vw' }} onHide={() => on3DSecurePopupHide()} resizable={false}>
                <iframe title="3d Secure" className="payment-iframe" src={auth3dSecureLink} width="100%" id="secure3dRef" onLoad={handleIframe} />
            </PR.Dialog>

            <PR.Toast ref={toast} position="top-right" />
        </>
    );
};

export default StripePayment;